<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

interface Form {
  name: string;
  site_name: string;
  sites: object;
  slug: string;
  pce: string;
  prm: string;
}

const { t } = useI18n();
const { showToast } = toastStore();
const submiting = ref(false);

const modal = defineModel<boolean>();

const closeModal = () => {
  modal.value = false;
};

const schema = yup.object().shape({
  name: yup.string().required(t('global.name_required')),
  site_name: yup.string().required(t('global.site_name_required')),
  pce: yup.string().test('prm-or-pce', t('global.prm_pce_must_field'), function (value) {
    if (values.pce === '' && values.prm === '') {
      return this.createError({
        path: 'pce',
        message: t('global.prm_pce_must_field'),
      });
    }
    if (value && !value.startsWith('G')) {
      return this.createError({ path: 'pce', message: t('global.pce_start_with_G') });
    }
    return true;
  }),

  prm: yup.string().test('prm-or-pce', t('global.prm_pce_must_field'), function (value) {
    if (values.pce === '' && values.prm === '') {
      return this.createError({
        path: 'prm',
        message: t('global.prm_pce_must_field'),
      });
    }

    if (value && value.length !== 14) {
      return this.createError({ path: 'prm', message: t('global.prm_14_characters_long') });
    }
    return true;
  }),
});

const { handleSubmit, values } = useForm<Form>({
  validationSchema: schema,
});

function getMetersData(pce?: string, prm?: string) {
  const meters: any[] = [];

  if (pce)
    meters.push({
      data_source_id: pce,
      meter_type_id: meterTypesStore().getMeterTypeFromName('grdf')?.id,
    });

  if (prm)
    meters.push({
      data_source_id: prm,
      meter_type_id: meterTypesStore().getMeterTypeFromName('enedis')?.id,
    });

  return meters;
}

const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    const data = {
      name: values.name,
      slug: values.name,
      sites: {
        data: [
          {
            name: values.name,
            areas: {
              data: [
                {
                  name: values.site_name,
                  meters: {
                    data: getMetersData(values.pce, values.prm),
                  },
                },
              ],
            },
          },
        ],
      },
    };
    await clientStore().createClient(data);
    await clientStore().loadClients();
    showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.client_created') });
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  submiting.value = false;
  closeModal();
});
</script>

<template>
  <ui-modal v-model="modal" width="700" :title="t('gloabal.create_client')">
    <div class="grid">
      <ui-form-input-text :label="$t('global.name')" :placeholder="$t('global.name')" autocomplete="off" required name="name" />

      <ui-form-input-text
        :label="$t('global.site_name')"
        :placeholder="$t('global.site_name')"
        autocomplete="off"
        required
        name="site_name"
      />

      <ui-form-input-text :label="$t('global.pce_id')" :placeholder="$t('global.pce_id')" autocomplete="off" required name="pce" />

      <ui-form-input-text :label="$t('global.prm_id')" :placeholder="$t('global.prm_id')" autocomplete="off" required name="prm" />
    </div>
    <template #footer>
      <ui-button :loading="submiting" @click="submit()">
        {{ t('gloabal.create_client') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
