<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { cleanJsonBeforeSend } from '~/helpers/data';
import type { User } from '~/types/user';

interface Form extends User {
  password: string;
}
// Composables
const { t } = useI18n();
const { postUser } = useApi();
const { proxy } = useScriptSegment();

// Data
const id = ref('new');

const modal = defineModel<boolean>();

const closeModal = () => {
  modal.value = false;
};

const submiting = ref(false);
const { showToast } = toastStore();

const props = defineProps<{
  user: User | undefined;
}>();

const title = computed(() => (props.user ? t('manage_user.edit_user') + ` : ${props.user.first_name}` : t('manage_user.create_user')));

const schema = yup.object({
  showEmailPasswordField: yup.boolean(),
  client_id: yup.string().required(t('global.company_is_required')),
  first_name: yup.string().required(t('global.first_name_is_required')),
  last_name: yup.string().required(t('global.last_name_is_required')),
  email: yup.string().when('showEmailPasswordField', {
    is: true,
    then: (schema) => schema.required(t('global.email_is_required')),
  }),
  password: yup.string().when('showEmailPasswordField', {
    is: true,
    then: (schema) => schema.required(t('global.password_is_required')),
  }),
  urlPhoto: yup.string(),
  role: yup.string(),
});

schema.describe({ value: { showEmailPasswordField: props.user ? false : true } });

const companies = computed(() => {
  const companies = clientStore().getClients;
  const companiesNames = companies.map((client) => {
    return { key: client.id, label: client.name };
  });
  return companiesNames;
});

const roles = computed(() => {
  return [
    { key: 'admin_app', label: t('global.administrator') },
    { key: 'user', label: t('global.user') },
  ];
});

const { handleSubmit, setValues } = useForm<Form>({
  validationSchema: schema,
});

const password = ref('');
const email = ref('');

const iconCopyPassword = ref('Copy');
const iconCopyEmail = ref('Copy');

const genPassword = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const passwordLength = 16;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  let password = '';
  while (!passwordRegex.test(password)) {
    password = '';
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
  }
  return password;
};

async function copyEmailToClipboard() {
  await navigator.clipboard.writeText(email.value);
  iconCopyEmail.value = 'Check';
  showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.email_copied') });
  setTimeout(() => {
    iconCopyEmail.value = 'Copy';
  }, 4000);
}

async function copyPasswordToClipboard() {
  await navigator.clipboard.writeText(password.value);
  iconCopyPassword.value = 'Check';
  showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.password_copied') });
  setTimeout(() => {
    iconCopyPassword.value = 'Copy';
  }, 4000);
}

const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    if (props.user) {
      const dataUserWithoutEmail = cleanJsonBeforeSend({ jsonObject: values, keys: ['email', 'password'] });
      await postUser(props.user.id, dataUserWithoutEmail);

      await userStore().loadUsers();
      showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.user_edited') });
    } else {
      const userCreated = await postUser(id.value, values);

      proxy.track('Account Created', {
        email: userCreated.email,
      });
      await userStore().loadUsers();
      showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.user_created') });
    }
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  submiting.value = false;
  closeModal();
});

function setYupValues(user: User | undefined) {
  const client_id = user ? user.client.id : '';
  const first_name = user ? user.first_name : '';
  const last_name = user ? user.last_name : '';
  const email = user ? user.email : '';
  const picture_url = user ? user.picture_url : '';
  const role = user ? user.role : 'user';
  const password = genPassword();

  setValues({ client_id, first_name, last_name, email, picture_url, role, password });
}

watch(
  () => modal,
  () => setYupValues(props.user),
  { deep: true, immediate: true },
);
</script>

<template>
  <ui-modal v-model="modal" width="700" :title="title">
    <div class="grid">
      <ui-form-input-select
        :items="companies"
        :label="t('global.company')"
        :placeholder="t('global.form.company_placeholder')"
        autocomplete="off"
        required
        name="client_id"
      />
      <ui-form-input-text
        :label="$t('global.first_name')"
        :placeholder="$t('global.form.first_name_placeholder')"
        autocomplete="off"
        required
        value="james"
        name="first_name"
      />
      <ui-form-input-text
        :label="t('global.last_name')"
        :placeholder="t('global.form.last_name_placeholder')"
        autocomplete="off"
        required
        name="last_name"
      />
      <div v-if="!props.user" class="relative flex items-end gap-3">
        <ui-form-input-text
          v-model:model-value="email"
          :label="t('global.email')"
          :placeholder="t('global.form.email_placeholder')"
          autocomplete="off"
          name="email"
          :disabled="props.user !== undefined"
        />
        <div class="cursor-pointer mb-4" @click="copyEmailToClipboard()">
          <ui-button class="!py-2" color="secondary">
            <ui-icon :name="iconCopyEmail" :size="20" />
          </ui-button>
        </div>
      </div>
      <div v-if="!props.user" class="relative flex items-end gap-3">
        <ui-form-input-text
          v-model:model-value="password"
          :label="t('global.password')"
          :placeholder="t('global.form.password_placeholder')"
          autocomplete="off"
          required
          name="password"
        />
        <div class="cursor-pointer mb-4" @click="copyPasswordToClipboard()">
          <ui-button class="!py-2" color="secondary">
            <ui-icon :name="iconCopyPassword" :size="20" />
          </ui-button>
        </div>
      </div>

      <ui-form-input-text :label="t('global.photo_url')" :placeholder="t('global.photo_url')" autocomplete="false" name="picture_url" />
      <ui-form-input-select :label="t('global.role')" :items="roles" :placeholder="t('global.form.role_placeholder')" name="role" />
    </div>
    <template #footer>
      <ui-button :loading="submiting" @click="submit()">
        {{ props.user ? t('manage_user.edit_user') : t('manage_user.create_user') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
