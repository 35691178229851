<script setup lang="ts">
import { useI18n } from 'vue-i18n';

// Components
import UserTab from '@/app-modules/admin/components/tabs/UserTab.vue';
import ClientTab from '@/app-modules/admin/components/tabs/ClientTab.vue';

const { t } = useI18n();

const state = reactive({
  currentTab: 0,
  listTabs: [
    { label: t('global.users'), id: 0 },
    { label: t('global.clients'), id: 1 },
  ],
});
</script>

<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="t('global.settings')" />
    <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
      <ui-card>
        <ui-tab v-model="state.currentTab" :list-tabs="state.listTabs" />
      </ui-card>
      <user-tab v-if="state.currentTab === 0" />
      <client-tab v-if="state.currentTab === 1" />
    </div>
  </div>
</template>
