<script setup lang="ts">
import { useI18n } from 'vue-i18n';

// Components
import ClientManageModal from '@/app-modules/admin/components/modals/ClientManageModal.vue';

// Data
const clientModal = ref(false);
const search = ref('');

const { t } = useI18n();

const openModal = () => {
  clientModal.value = true;
};

const filteredClients = computed(() => {
  return clientStore().getClients.filter((client) => {
    if (search.value == null || search.value === '' || search.value === undefined) {
      return true;
    } else {
      return client.name.toLowerCase().includes(search.value.toLowerCase());
    }
  });
});
</script>

<template>
  <section class="flex flex-col gap-4 h-full w-full rounded-md overflow-y-hidden">
    <ui-card :title="$t('global.clients')">
      <template #header>
        <ui-search-bar v-model="search" class="w-full" />
        <ui-button left-icon="Plus" @click="openModal()">
          {{ t('global.add') }}
        </ui-button>
      </template>
      <ui-data-table :headers="[{ label: $t('global.name'), key: 'name' }]" :items="filteredClients" />
    </ui-card>
  </section>

  <client-manage-modal v-model="clientModal" />
</template>
