<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { User } from '~/types/user';

// Components
import UserManageModal from '@/app-modules/admin/components/modals/UserManageModal.vue';

const { t } = useI18n();

const user: any = computed(() => {
  return userStore().getCurrentUser;
});

const usersModal = ref(false);
const loading = ref(false);

const openModal = (user?: any) => {
  usersModal.value = true;
  selectedUser.value = user;
};

const selectedUser = ref<User | null>(null);

const state = reactive({
  active_users: [] as any,
  disabled_users: [] as any,
  searchedValue: '',
  userModalValue: null,
});

const client = computed(() => {
  return (client_id: number) => {
    return clientStore().getClient(client_id) ?? { name: `client not found : ${client_id}` };
  };
});

async function loadData() {
  loading.value = true;
  const users = userStore().getAllUsers;
  state.active_users = users?.filter((u) => !u?.disabled);
  state.disabled_users = users?.filter((u) => u?.disabled);
  loading.value = false;
}

onMounted(() => {
  loadData();
});

watch(
  () => userStore().getUsers,
  () => loadData(),
  { immediate: true },
);

const headers: Header[] = [
  { label: t('global.client_id'), key: 'client_id' },
  { label: t('global.first_name'), key: 'first_name' },
  { label: t('global.name'), key: 'last_name' },
  { label: t('global.client'), key: 'client_related' },
  { label: t('global.entity'), key: 'entity_name' },
  { label: t('global.email'), key: 'email' },
  { label: t('global.role'), key: 'role' },
  { label: t('global.action'), key: 'actions' },
];

const filterdUsers = computed(() => {
  return state.active_users.filter((u) => {
    return (
      u.first_name.toLowerCase().includes(state.searchedValue.toLowerCase()) ||
      u.last_name.toLowerCase().includes(state.searchedValue.toLowerCase()) ||
      u.email.toLowerCase().includes(state.searchedValue.toLowerCase())
    );
  });
});

const filterdDisabledUsers = computed(() => {
  return state.disabled_users.filter((u) => {
    return (
      u.first_name.toLowerCase().includes(state.searchedValue.toLowerCase()) ||
      u.last_name.toLowerCase().includes(state.searchedValue.toLowerCase()) ||
      u.email.toLowerCase().includes(state.searchedValue.toLowerCase())
    );
  });
});
</script>

<template>
  <ui-card :title="$t('global.users')">
    <template #header>
      <ui-search-bar v-model="state.searchedValue" class="w-full" />
      <ui-button left-icon="Plus" @click="openModal()">
        {{ t('global.add') }}
      </ui-button>
    </template>

    <ui-data-table :headers="headers" :items="filterdUsers" @click-row="openModal($event)">
      <template v-if="user.role === 'admin_app'" #item-client_id="{ item }">
        <ui-badge color="blue">
          {{ item.client_id }}
        </ui-badge>
      </template>
      <template #item-first_name="{ item }">
        {{ item.first_name }}
      </template>

      <template #item-last_name="{ item }">
        {{ item.last_name }}
      </template>

      <template #item-email="{ item }">
        {{ item.email }}
      </template>

      <template #item-client_related="{ item }">
        {{ client(item.client_id).name }}
      </template>

      <template #item-role="{ item }">
        <ui-badge :color="item.role === 'admin_app' ? 'red' : 'green'">
          {{ item.role }}
        </ui-badge>
      </template>

      <template #item-actions="{ item }">
        <div
          class="flex items-center justify-center hover:text-gray-900 hover:bg-gray-100 rounded-lg h-[28px] w-[28px] cursor-pointer"
          @click="openModal(item)"
        >
          <ui-icon name="Pencil" class="w-4 h-4 text-gray-600" />
        </div>
      </template>
    </ui-data-table>
  </ui-card>

  <ui-card :title="$t('global.disabled_users')">
    <ui-data-table :headers="headers" :items="filterdDisabledUsers" :loading="loading" @click-row="openModal($event)">
      <template v-if="user.role === 'admin_app'" #item-client_id="{ item }">
        {{ item.client_id }}
      </template>
      <template #item-first_name="{ item }">
        {{ item.first_name }}
      </template>

      <template #item-last_name="{ item }">
        {{ item.last_name }}
      </template>

      <template #item-email="{ item }">
        {{ item.email }}
      </template>

      <template #item-role="{ item }">
        <ui-badge :color="item.role === 'admin_app' ? 'red' : 'green'">
          {{ item.role }}
        </ui-badge>
      </template>

      <template #item-actions="{ item }">
        <div
          class="flex items-center justify-center hover:text-gray-900 hover:bg-gray-100 rounded-lg h-[28px] w-[28px] cursor-pointer"
          @click="openModal(item)"
        >
          <ui-icon name="Pencil" class="w-4 h-4 text-gray-600" />
        </div>
      </template>
    </ui-data-table>
  </ui-card>

  <user-manage-modal v-model="usersModal" :user="selectedUser" />
</template>
